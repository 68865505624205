import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import Cookie from "js-cookie";
import { getQuery } from "@/utils/tool";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { Message } from "element-ui";
import authService from "@/global/service/auth";
import Store from "@/store/index";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.firstInit = false;
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  const TOKEN = Cookie.get("token");
  const referer = getQuery("referer");
  if (!TOKEN) {
    router.firstInit = false;
  }
  if (!TOKEN && to.name !== "JkxyLogin" && to.name !== "JkxyRegister") {
    Message.error("请先登录或注册账号");
    next({ name: "JkxyLogin", replace: true });
    return;
  }
  if (TOKEN && referer) {
    window.location.href = referer;
    return;
  }
  if (TOKEN && !referer && !router.firstInit) {
    router.firstInit = true;
    const userInfo = await authService.userProfile();
    Store.commit("getUserInfo", userInfo);
  }
  if (TOKEN && !referer && to.name === "JkxyLogin") {
    next({ name: "JkxyUserCenter", replace: true });
  }
  next();
});

// eslint-disable-next-line
router.afterEach(async (to, from) => {
  NProgress.done();
  // eslint-disable-next-line
  // typeof gio === "function" && gio("send");
  // // eslint-disable-next-line
  // window.collectEvent('predefinePageView')
});

export default router;
