import API from "@/global/request/api";
import axios from "@/global/request/axios";
const authService = {
  passwordLogin(params, obj) {
    return axios.post(API.passwordLogin, params, obj);
  },
  userRegister(params) {
    return axios.post(API.passwordRegister, params);
  },
  emailSend(params) {
    return axios.post(API.emailSend, params);
  },
  emailVerify(params) {
    return axios.post(API.emailVerify, params);
  },
  phoneSms(params) {
    return axios.post(API.phoneSms, params);
  },
  phoneLogin(params, config) {
    return axios.post(API.phoneLogin, params, config);
  },
  userProfile() {
    return axios.get(API.userProfile);
  },
  userSetting(params) {
    return axios.put(API.userSetting, params);
  },
  phoneBind(params) {
    return axios.put(API.phoneBind, params);
  },
  phoneUnbind() {
    return axios.put(API.phoneUnbind);
  },
  emailBind(params) {
    return axios.put(API.emailBind, params);
  },
  emailUnbind() {
    return axios.put(API.emailUnbind);
  },
};

export default authService;
