const routes = [
  {
    path: "/",
    name: "JkxyLogin",
    component: () => import("../views/AccountLogin.vue"),
  },
  {
    path: "/register",
    name: "JkxyRegister",
    component: () => import("../views/AccountRegister.vue"),
  },
  {
    path: "/",
    component: () => import("../components/TheLayout.vue"),
    children: [
      {
        path: "/user",
        name: "JkxyUserCenter",
        component: () => import("../views/UserCenter.vue"),
      },
      {
        path: "/order",
        name: "JkxyOrder",
        component: () => import("../views/Order.vue"),
      },
    ],
  },
];

export default routes;
