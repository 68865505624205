export function getQuery(name) {
  var result = location.search.match(
    new RegExp("[\\?\\&]" + name + "=([^\\&]+)", "i")
  );

  if (result == null || result.length < 1) {
    return "";
  }

  return decodeURIComponent(result[1]);
}

// export default {
//   getQuery
// };
