// const PREFIX = process.env.VUE_APP_ENV === "uat" ? "uat-" : "";
const PASSPORT_PREFIX = `https://api` + process.env.VUE_APP_DOMAIN;
export default {
  passwordLogin: `${PASSPORT_PREFIX}/api/passport/auth/pw-login`,
  passwordRegister: `${PASSPORT_PREFIX}/api/passport/auth/pw-register`,
  emailSend: `${PASSPORT_PREFIX}/api/passport/auth/email/send`,
  emailVerify: `${PASSPORT_PREFIX}/api/passport/auth/email/verify`,
  phoneSms: `${PASSPORT_PREFIX}/api/passport/auth/phone/sms`,
  phoneLogin: `${PASSPORT_PREFIX}/api/passport/auth/phone/login`,
  userProfile: `${PASSPORT_PREFIX}/api/passport/auth/user-profile`,
  userSetting: `${PASSPORT_PREFIX}/api/passport/auth/user-setting`,
  phoneBind: `${PASSPORT_PREFIX}/api/passport/auth/phone/bind`,
  phoneUnbind: `${PASSPORT_PREFIX}/api/passport/auth/phone/unbind`,
  emailBind: `${PASSPORT_PREFIX}/api/passport/auth/email/bind`,
  emailUnbind: `${PASSPORT_PREFIX}/api/passport/auth/email/unbind`,
};
