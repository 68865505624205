import Vue from "vue";
import Vuex from "vuex";
import Cookie from "js-cookie";
import Router from "@/router/index";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    userInfo: {},
  },
  mutations: {
    getUserInfo(state, data) {
      state.userInfo = data;
    },
  },
  actions: {
    logout() {
      const DOMAIN = process.env.VUE_APP_DOMAIN;
      Cookie.remove("token", { domain: DOMAIN });
      Cookie.remove("uname", { domain: DOMAIN });
      Cookie.remove("uid", { domain: DOMAIN });
      Router.push({ name: "JkxyLogin" });
    },
  },
  modules: {},
});
export default store;
